.rodape {
    background-color: #f0f0f0;
    padding: 20px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: rgb(0, 0, 0);
    font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px) {
    .rodape {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 60px;
    }

    .rodape-info,
    .rodape-contato,
    .rodape-pagamento,
    .rodape-seguranca,
    .rodape-envio,
    .rodape-social,
    .rodape-departamentos,
    .rodape-participe {
        text-align: left;
        margin-left: 0;
        margin-bottom: -10px; /* Reduce spacing for mobile */
        width: 100%;
    }

    .rodape-departamentos {
        margin-left: 0;
    }

    .rodape-departamentos ul {
        padding-left: 0;
        list-style-type: none;
    }

    .rodape-departamentos li {
        margin-bottom: 2px; /* Reduce spacing */
        text-align: left;
    }
}

.bandeiras-envio {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.bandeiras-envio svg, 
.bandeiras-envio img { 
    width: 35px;
    height: 35px;
    color: inherit; 
}

.rodape-pagamento-seguranca-envio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px; 
    margin-bottom: 10px; 
}

.rodape-info,
.rodape-contato,
.rodape-pagamento,
.rodape-seguranca,
.rodape-envio,
.rodape-social,
.rodape-participe {
    flex: 1;
    min-width: 200px;
    margin-bottom: 10px; /* Reduce spacing */
    text-align: left;
    margin-left: 70px; 
}

.rodape-contato h3,
.rodape-info h3,
.rodape-pagamento h3,
.rodape-seguranca h3,
.rodape-envio h3,
.rodape-social h3,
.rodape-participe h3 { /* Added for Participe */
    margin-bottom: 8px; /* Reduce spacing */
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: left;
}

.rodape p {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 14px;
    text-align: left;
}

.bandeiras-pagamento svg,
.bandeiras-envio svg {
    font-size: 24px;
    color: inherit;
}

.bandeiras-pagamento svg {
    font-size: 16px;
    color: #1d1d1d;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 2px;
    width: 28px;
    height: 28px;
}

.bandeiras-pagamento .pix-icon {
    color: #00B894;
}

.social-icons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #000;
}

.social-icons svg {
    color: #000;
    font-size: 20px;
}

.social-icons a {
    display: flex;
    align-items: center;
    margin-bottom: 8px; /* Reduce spacing */
    text-decoration: none;
}

.social-icons span {
    margin-left: 6px; /* Adjusted for consistency */
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #000;
}

.seguranca-icones {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.seguranca-icones svg, 
.seguranca-icones img { 
    width: 30px; 
    height: 30px;
    color: inherit; 
}

.ssl-icon {
    width: 40px; 
    height: 40px;
}

.rodape-info p {
    color: #707070;
    font-size: 12px; /* Reduced font size for the info text */
    text-align: left; 
    margin-left: 0; 
}

.rodape-contato p {
    color: #707070; 
    font-size: 12px; 
}

.rodape-contato p strong {
    color: #000; 
    font-weight: bold; 
    font-size: 13px; 
}

.social-icons span {
    color: #707070; 
}

.rodape-departamentos {
    flex: 1;
    min-width: 200px;
    margin-bottom: 10px;
    text-align: left; 
    margin-left: 70px; 
}

.rodape-departamentos h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 8px; /* Reduced */
    text-align: left;
}

.rodape-departamentos ul {
    list-style-type: none; 
    margin-top: -8px;
}

.rodape-departamentos li {
    margin-bottom: 0px; /* Reduced */
    text-align: left; 
}

.rodape-departamentos li a {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #707070;
    text-decoration: none;
    transition: color 0.3s;
}

.rodape-departamentos li a:hover {
    color: #000; 
}

/* New styles for the Participe section */
.rodape-participe {
    flex: 1;
    min-width: 200px;
    margin-bottom: 10px; /* Reduced spacing */
}

/* Styles for the Instagram group link */
.rodape-participe a {
    color: #707070; /* Match the color with other text */
    text-decoration: none; /* Remove underline */
}

.rodape-participe a:hover {
    color: #000; /* Change color on hover */
}
.rodape-participe p {
    margin-top: -6px; /* Reduz o espaço entre o título e o link */
    
}

/* Footer Info styles */
.rodape-info h3 {
    font-size: 16px; /* Reduced font size for title */
}

.rodape-info p {
    font-size: 11px; /* Reduced font size for info text */
}
