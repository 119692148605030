/* Estilização geral do carrinho */
.carrinho {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Cabeçalho do carrinho */
.carrinho-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.carrinho-header h2 {
  font-size: 18px;
  color: #000000;
}

.close-btn {
  color: #000000;
  background-color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  opacity: 1; /* Garante que o botão esteja sempre visível */
  transition: opacity 0.3s;
}

.close-btn:hover {
  opacity: 0.8; /* Efeito de hover para o botão */
  background-color: #fff;
}


/* Conteúdo do carrinho */
.carrinho-content {
  flex: 1;
  padding: 10px;
}

.carrinho-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.carrinho-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.carrinho-item-info {
  flex: 1;
}

.carrinho-item-info h3 {
  font-size: 16px;
}

.carrinho-item-info .price {
  font-size: 14px;
  color: #000000;
}

/* Estilização dos botões de mais e menos */
.quantity-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-controls button {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 50%; /* Botões circulares */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.quantity-controls button:hover {
  background-color: #2f2f30;
  transform: scale(1.1); /* Aumenta ligeiramente o botão ao passar o mouse */
}

.quantity-controls span {
  font-size: 18px; /* Aumenta o tamanho da fonte do número */
  margin: 0 10px; /* Adiciona espaço entre o número e os botões */
  font-weight: bold; /* Deixa o número em negrito */
}

/* Estilização do rodapé do carrinho */
.carrinho-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
}

/* Estilização do total */
.carrinho-footer p {
  font-size: 20px; /* Aumenta o tamanho da fonte */
  font-weight: bold; /* Deixa o texto em negrito */
  color: #000000; /* Mantém a cor destacada */
  margin-bottom: 15px; /* Adiciona um espaço entre o total e o botão */
  text-align: center; /* Centraliza o texto */
}

/* Estilização do botão de finalizar compra */
.checkout-btn {
  background-color: #000000; /* Mantém a cor de fundo */
  color: #fff;
  border: none;
  padding: 15px; /* Aumenta o padding para dar mais espaço */
  width: 100%;
  cursor: pointer;
  font-size: 18px; /* Aumenta o tamanho da fonte */
  border-radius: 30px; /* Bordas arredondadas */
  transition: background-color 0.3s, transform 0.3s; /* Animação para efeito de hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra */
}

.checkout-btn:hover {
  background-color: #313033; /* Muda a cor ao passar o mouse */
}
/* Ajustes para Mobile */
@media (max-width: 768px) {
  .carrinho {
    width: 80%; /* Ocupa 80% da largura da tela */
    height: 100%;
    top: 0;
    right: 0;
  }

  .carrinho-header h2 {
    font-size: 16px; /* Tamanho do título reduzido */
  }

  .carrinho-content {
    padding: 5px; /* Reduz o padding */
  }

  .carrinho-item {
    padding: 8px 0; /* Reduz o padding entre os itens */
  }

  .carrinho-item-image {
    width: 40px; /* Tamanho menor para a imagem do item */
    height: 40px;
    margin-right: 8px;
  }

  .carrinho-item-info h3 {
    font-size: 14px; /* Reduz o tamanho do título dos itens */
  }

  .quantity-controls button {
    padding: 8px; /* Tamanho menor dos botões */
    font-size: 14px; /* Tamanho menor da fonte */
  }

  .quantity-controls span {
    font-size: 16px; /* Tamanho menor para o número */
  }

  .checkout-btn {
    padding: 12px; /* Reduz o padding do botão */
    font-size: 16px; /* Reduz o tamanho da fonte */
  }

  .carrinho-footer p {
    font-size: 18px; /* Reduz o tamanho da fonte do total */
    margin-bottom: 10px; /* Reduz o espaço entre o total e o botão */
  }
}
