.categorias {
  position: relative;
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  font-size: 30px;
  color: #000000;
}

.categorias-dropdown {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: auto; /* Largura automática */
  max-width: 200px; /* Largura máxima */
  z-index: 2000 !important; 
}

.categorias-dropdown ul {
  list-style-type: none;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column; /* Coloca as categorias em uma coluna */
  max-height: 300px; /* Limita a altura */
  overflow-y: auto; /* Rolagem vertical */
}

.categorias-dropdown li {
  margin-bottom: 10px; 
  padding-bottom: 5px;
  border-bottom: 1px solid #000000; 
}

.categorias-dropdown li:last-child {
  border-bottom: none; 
}

.categorias-dropdown li a {
  color: #000000;
  text-decoration: none;
  font-family: 'Poppins', sans-serif; /* Atualizado para Poppins */
  font-weight: bold;
  display: block; 
  max-width: 180px; /* Largura máxima do link */
  font-size: 19px; /* Tamanho de fonte reduzido */
}

.categorias-dropdown li a:hover {
  text-decoration: underline;
}
